import React from "react";
import "./App.css";

function App() {
  return (
    <main className="main">
      <header className="header">
        <div className="company-name">Gigagroup</div>
        <img
          src="https://gigapipe.com/wp-content/uploads/2022/02/Gigapipe-icon-purple.png"
          alt="Gigagroup logo"
          className="logo"
        />
        <h1 className="title">Data Observability Platform</h1>
        <h2 className="subtitle">(Coming soon!)</h2>
        <a href="mailto:info@gigagroup.io" className="button">
          Sign up to beta
        </a>
      </header>

      <div className="center"></div>

      <div className="grid">
        <div className="card">
          <h2>Monitoring</h2>
          <p>
            Real-time monitoring of your data with customizable alerts and
            notifications.
          </p>
        </div>
        <div className="card">
          <h2>Analysis</h2>
          <p>
            Deep analysis of your data to uncover hidden patterns and insights.
          </p>
        </div>
        <div className="card">
          <h2>Quality</h2>
          <p>
            Ensure data quality with automated validation and error detection.
          </p>
        </div>
        <div className="card">
          <h2>Collaboration</h2>
          <p>
            Collaborate with your team on data analysis and monitoring tasks.
          </p>
        </div>
      </div>
      <div className="footer">
        <p>&copy; 2023 Gigagroup. All rights reserved.</p>
      </div>
    </main>
  );
}

export default App;
